// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './Login';
import Dashboard from './Dashboard';

function App() {
  return (
    <Router>
      <Routes>
        {/* Tela de login */}
        <Route path="/" element={<Login />} />
        {/* Dashboard protegido */}
        <Route path="/caruaru-campaing0023" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
