// src/Login.js

import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  CssBaseline,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Particles from 'react-tsparticles';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  // Configurações das Partículas (opcionalmente, você pode reutilizar as configurações)
  const particlesOptions = {
    fullScreen: {
      enable: true,
      zIndex: -1,
    },
    particles: {
      number: {
        value: 50,
        density: {
          enable: true,
          area: 800,
        },
      },
      color: {
        value: '#ffffff',
      },
      size: {
        value: 2,
      },
      move: {
        speed: 1,
      },
      opacity: {
        value: 0.5,
      },
    },
    background: {
      color: '#2B2B2B',
    },
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Verificar as credenciais
    if (username === 'caruaru0023' && password === 'C@ru@ru02123') {
      // Armazenar a autenticação (simplesmente usando o localStorage neste exemplo)
      localStorage.setItem('auth', 'true');
      navigate('/caruaru-campaing0023');
    } else {
      setError('Usuário ou senha incorretos.');
    }
  };

  return (
    <>
      <CssBaseline />
      <Particles id="tsparticles" options={particlesOptions} />
      <img src={require('./assets/logo.png')} alt="Logo" style={{ display: 'block', margin: '0 auto', width: '200px', marginTop: '5rem'}} />
      <Container maxWidth="sm" style={{ marginTop: '5rem', zIndex: 1 }}>
        <Paper
          elevation={3}
          style={{
            padding: '2rem',
            backgroundColor: 'rgba(26, 26, 26, 0.9)',
            color: '#FFFFFF',
          }}
        >
          <Typography variant="h5" style={{ marginBottom: '1rem', color: '#39FF14' }}>
            Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              label="Usuário"
              variant="filled"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ marginBottom: '1rem' }}
              InputProps={{
                style: { color: '#FFFFFF' },
              }}
              InputLabelProps={{
                style: { color: '#888888' },
              }}
            />
            <TextField
              fullWidth
              label="Senha"
              variant="filled"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: '1rem' }}
              InputProps={{
                style: { color: '#FFFFFF' },
              }}
              InputLabelProps={{
                style: { color: '#888888' },
              }}
            />
            {error && (
              <Typography variant="body2" style={{ color: '#d32f2f', marginBottom: '1rem' }}>
                {error}
              </Typography>
            )}
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Entrar
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
}

export default Login;
