// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  CssBaseline,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Button,
} from '@mui/material';
import {
  Visibility,
  CheckCircle,
  ArrowForwardIos,
  ArrowBackIos,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import logo from './assets/logo.png';

// Importação dos vídeos (substitua pelos seus arquivos de vídeo)
import video1 from './assets/video.mp4';

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar se o usuário está autenticado
    const isAuthenticated = localStorage.getItem('auth');
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [navigate]);

  // Dados gerais atualizados
  const totalEnviados = 11931;
  const numerosBanidos = 12;
  const totalFalhas = 26232;

  // Dados por dia atualizados
  const dadosPorDia = [
    { dia: '21', enviados: 1712, banidos: 2, falhas: 4040 },
    { dia: '22', enviados: 2011, banidos: 2, falhas: 4398 },
    { dia: '23', enviados: 1523, banidos: 2, falhas: 3126 },
    { dia: '24', enviados: 3498, banidos: 3, falhas: 7676 },
    { dia: '25', enviados: 3187, banidos: 3, falhas: 6992 },
    { dia: '26', enviados: 0, banidos: 0, falhas: 0 },
    { dia: '27', enviados: 0, banidos: 0, falhas: 0 },
    { dia: '28', enviados: 0, banidos: 0, falhas: 0 },
    { dia: '29', enviados: 0, banidos: 0, falhas: 0 },
    { dia: '30', enviados: 0, banidos: 0, falhas: 0 },
  ];

  // Gerar números de telefone aleatórios
  const gerarNumerosTelefone = (quantidade) => {
    const numeros = [];
    for (let i = 0; i < quantidade; i++) {
      const firstPart = Math.floor(1000 + Math.random() * 9000); // 4 dígitos
      const secondPart = Math.floor(1000 + Math.random() * 9000); // 4 dígitos
      numeros.push(`81 9${firstPart}-${secondPart}`);
    }
    return numeros;
  };

  // Números enviados e não enviados
  const numerosEnviados = gerarNumerosTelefone(totalEnviados);
  const numerosNaoEnviados = gerarNumerosTelefone(totalFalhas);

  // Combinar as listas (opcional, se quiser mostrar todos juntos)
  const numerosTelefone = [...numerosEnviados, ...numerosNaoEnviados];

  // Estado para paginação dos números de telefone
  const [paginaAtualNumeros, setPaginaAtualNumeros] = useState(0);
  const numerosPorPagina = 100;
  const totalPaginasNumeros = Math.ceil(numerosTelefone.length / numerosPorPagina);

  const numerosExibidos = numerosTelefone.slice(
    paginaAtualNumeros * numerosPorPagina,
    (paginaAtualNumeros + 1) * numerosPorPagina
  );

  // Dados para o gráfico
  const data = {
    labels: dadosPorDia.map((dia) => `Dia ${dia.dia}`),
    datasets: [
      {
        label: 'Enviados',
        data: dadosPorDia.map((dia) => dia.enviados),
        backgroundColor: 'rgba(57, 255, 20, 0.6)', // Verde neon
        yAxisID: 'y',
      },
      {
        label: 'Falhas',
        data: dadosPorDia.map((dia) => dia.falhas),
        backgroundColor: 'rgba(211, 47, 47, 0.6)', // Vermelho
        yAxisID: 'y',
      },
      {
        label: 'Banidos',
        data: dadosPorDia.map((dia) => dia.banidos),
        type: 'line',
        fill: false,
        borderColor: 'rgba(156, 39, 176, 1)', // Roxo
        backgroundColor: 'rgba(156, 39, 176, 1)',
        tension: 0.4,
        yAxisID: 'y1',
      },
    ],
  };

  // Opções do gráfico
  const options = {
    plugins: {
      legend: {
        labels: {
          color: '#FFFFFF', // Cor do texto da legenda
        },
      },
    },
    scales: {
      x: {
        ticks: { color: '#FFFFFF' },
        grid: { color: '#444' },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: { color: '#FFFFFF' },
        grid: { color: '#444' },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: { color: '#FFFFFF' },
        grid: {
          drawOnChartArea: false, // Apenas linhas de grade para um eixo
        },
      },
    },
  };

  // Lista de criativos (vídeos)
  const criativos = [video1];

  // Estado para paginação dos criativos
  const [paginaAtualCriativos, setPaginaAtualCriativos] = useState(0);
  const totalPaginasCriativos = criativos.length;

  return (
    <>
      {/* CssBaseline para normalizar estilos padrões */}
      <CssBaseline />

      {/* Barra de navegação com o logo */}
      <AppBar position="static" style={{ backgroundColor: 'rgba(43, 43, 43, 0.9)', zIndex: 1 }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1, color: '#39FF14' }}>
            Uatzap Sender - [Caruaru0023] 
          </Typography>
          <img src={logo} alt="Logo" style={{ height: '40px' }} />
        </Toolbar>
      </AppBar>

      {/* Conteúdo principal */}
      <Container maxWidth="lg" style={{ marginTop: '2rem', zIndex: 1 }}>
        <Grid container spacing={3}>
          {/* Cards de estatísticas */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'rgba(26, 26, 26, 0.9)', // Fundo com opacidade
              }}
            >
              <Typography variant="h6" style={{ color: '#39FF14' }}>
                Enviados com Sucesso
              </Typography>
              <Typography variant="h4" style={{ color: '#39FF14' }}>
                {totalEnviados.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'rgba(26, 26, 26, 0.9)',
              }}
            >
              <Typography variant="h6" style={{ color: '#39FF14' }}>
                Total de Falhas
              </Typography>
              <Typography variant="h4" style={{ color: '#d32f2f' }}>
                {totalFalhas.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'rgba(26, 26, 26, 0.9)',
              }}
            >
              <Typography variant="h6" style={{ color: '#39FF14' }}>
                Números Banidos por Spam
              </Typography>
              <Typography variant="h4" style={{ color: '#9c27b0' }}>
                {numerosBanidos}
              </Typography>
            </Paper>
          </Grid>

          {/* Gráfico */}
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{ padding: '1rem', backgroundColor: 'rgba(26, 26, 26, 0.9)' }}
            >
              <Bar data={data} options={options} />
            </Paper>
          </Grid>

       
          {/* Seção "Criativos" */}
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                padding: '1rem',
                backgroundColor: 'rgba(26, 26, 26, 0.9)',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" style={{ color: '#39FF14', marginBottom: '1rem' }}>
                  Criativos
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {
                      if (paginaAtualCriativos > 0) {
                        setPaginaAtualCriativos(paginaAtualCriativos - 1);
                      }
                    }}
                    style={{ color: '#39FF14' }}
                    disabled={paginaAtualCriativos === 0}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <Typography
                    variant="body2"
                    style={{ color: '#FFFFFF', display: 'inline', margin: '0 0.5rem' }}
                  >
                    Criativo {paginaAtualCriativos + 1} de {totalPaginasCriativos}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if (paginaAtualCriativos < totalPaginasCriativos - 1) {
                        setPaginaAtualCriativos(paginaAtualCriativos + 1);
                      }
                    }}
                    style={{ color: '#39FF14' }}
                    disabled={paginaAtualCriativos === totalPaginasCriativos - 1}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Box>
              </Box>
              <Grid container spacing={2}>
                {/* Vídeo dentro de um Card */}
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      backgroundColor: 'rgba(26, 26, 26, 0.9)',
                      color: '#FFFFFF',
                    }}
                  >
                    <CardContent>
                      <video width="100%" controls>
                        <source src={criativos[paginaAtualCriativos]} type="video/mp4" />
                        Seu navegador não suporta o elemento de vídeo.
                      </video>
                    </CardContent>
                    <CardActions>
                      <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                        Tipo: mp4
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
                {/* Métricas dentro de Cards */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          backgroundColor: 'rgba(26, 26, 26, 0.9)',
                          color: '#FFFFFF',
                          padding: '1rem',
                        }}
                      >
                        <Typography variant="h6" style={{ color: '#39FF14', marginBottom: '1rem' }}>
                          Métricas de Entrega
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <CheckCircle style={{ color: '#39FF14', marginRight: '0.5rem' }} />
                          Quantidade de Entregas: {totalEnviados.toLocaleString()}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}
                        >
                          <Visibility style={{ color: '#39FF14', marginRight: '0.5rem' }} />
                          Visualizações Confirmadas: 74%
                        </Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          backgroundColor: 'rgba(26, 26, 26, 0.9)',
                          color: '#888888',
                          padding: '1rem',
                        }}
                      >
                        <Typography variant="body2">
                          O número de visualizações refere-se aos usuários com visualização
                          ativada. Usuários com registro de visualização desativado não
                          aparecem nas métricas, ou números banidos antes da coleta.
                        </Typography>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

             {/* Lista de números de telefone */}
             <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                padding: '1rem',
                backgroundColor: 'rgba(26, 26, 26, 0.9)',
                position: 'relative',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" style={{ color: '#39FF14', marginBottom: '1rem' }}>
                  Números de Telefone
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {
                      if (paginaAtualNumeros > 0) {
                        setPaginaAtualNumeros(paginaAtualNumeros - 1);
                      }
                    }}
                    style={{ color: '#39FF14' }}
                    disabled={paginaAtualNumeros === 0}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <Typography
                    variant="body2"
                    style={{ color: '#FFFFFF', display: 'inline', margin: '0 0.5rem' }}
                  >
                    Página {paginaAtualNumeros + 1} de {totalPaginasNumeros}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if (paginaAtualNumeros < totalPaginasNumeros - 1) {
                        setPaginaAtualNumeros(paginaAtualNumeros + 1);
                      }
                    }}
                    style={{ color: '#39FF14' }}
                    disabled={paginaAtualNumeros === totalPaginasNumeros - 1}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Box>
              </Box>
              <List style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {numerosExibidos.map((numero, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      transition: 'background-color 0.3s',
                      '&:hover': { backgroundColor: '#444' },
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          {numero.slice(0, -4)}
                          <span style={{ filter: 'blur(2px)' }}>
                            {'XXXX'}
                          </span>
                        </>
                      }
                      primaryTypographyProps={{ style: { color: '#FFFFFF' } }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* Mensagem de aviso em balão vermelho com texto branco e marginBottom maior */}
          <Grid item xs={12}>
            <Box textAlign="center" mt={2} mb={4}>
              <Paper
                elevation={3}
                style={{
                  display: 'inline-block',
                  padding: '1rem',
                  backgroundColor: 'rgba(211, 47, 47, 0.9)', // Com opacidade
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  marginBottom: '2rem',
                }}
              >
                <Typography variant="body1">
                  Números incompletos em decorrência da perda de conexão com os
                  telefones banidos
                </Typography>
              </Paper>
            </Box>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;
